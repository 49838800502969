import vueActivity from '@imt/vue-activity/src/store';
import alerts from '@imt/vue-admin-menus/src/store/alerts';
import notifications from '@imt/vue-admin-menus/src/store/notifications';
import businessRulesDebugger from '@imt/vue-business-rules-debugger/src/store';
import {configuration} from '@imt/vue-configuration/src/store';
import {asString, defaultParamMap} from '@imt/vue-list/src/utils/queryParams';
import toolbox from '@imt/vue-toolbox/src/store';
import {deepCase} from '@imt/vue-toolbox/src/utils';
import utils, {axiosWithAuth, getCookieValue} from '@imt/vue-toolbox/src/utils';
import {vueVersioning} from "@imt/vue-versioning/src/store";
import {webFormEditor} from '@imt/vue-web-form-editor/src/store';
import kebabCase from 'lodash/kebabCase';
import Vue from 'vue';
import Vuex from 'vuex';

import fakeClientStore from '@/fake_client/store';
import policySystem from '@/store/policy-system-front-end';
import wording from '@/utils';

Vue.use(Vuex);

export const authedAxios = axiosWithAuth();

async function callApi(method = 'patch', url = '/', stuff = {}) {
    method = method.toLowerCase();

    return await authedAxios[method](
        url,
        method === 'delete' ? {} : utils.dataFormatter.serialize({stuff})
    );
}

export const getters = {
    policyLineOptions() {
        return [
            {display: 'Commercial', value: 'commercial'},
            {display: 'Personal', value: 'personal'},
        ];
    },
    pageTypeOptions() {
        return [
            {display: 'Other', value: 'other'},
            {display: 'Quote', value: 'quote'},
            {display: 'Submission', value: 'submission'},
        ];
    },
    transactionTypeOptions() {
        return [
            {display: 'Amendment', value: 'amendment'},
            {display: 'Cancellation', value: 'cancel'},
            {display: 'Inquiry', value: 'inquiry'},
            {display: 'New Business', value: 'new_business'},
            {display: 'Reapply', value: 'reapply'},
            {display: 'Reinstatement', value: 'reinstate'},
            {display: 'Renewal', value: 'renewal'},
            {display: 'Quote', value: 'quote'},
        ];
    },
};

export const actions = {
    async createDraft({dispatch, state}, {versionId, itemType, itemId}) {
        const response = await authedAxios.post(
            `/policy-systems/${state.policySystemId}/${kebabCase(itemType)}s/${itemId}/versions/`,
            utils.dataFormatter.serialize({stuff: {id: versionId, type: 'Version'}})
        );

        await dispatch(`fetch${itemType}s`, {});

        return response.data ? utils.dataFormatter.deserialize(response.data) : {};
    },
    async fetchPolicySystems({commit}, queryObject) {
        let queryString = asString(queryObject, {
                ...defaultParamMap,
                ...wording.searchParams,
            }),
            url = `/policy-systems/?include=logo,created_by,updated_by`;
        const response = await authedAxios.get(
            `${url}${queryString}`
        );
        commit('SET_DATA', {field: 'policySystems', data: utils.dataFormatter.deserialize(response.data)});
        commit('SET_DATA', {field: 'totalPolicySystemCount', data: response.data.meta.pagination.count});

        return response;
    },
    async fetchEnvPolicySystems(context, targetEnvironment) {
        const response = await authedAxios.get(`/policy-systems/?environment=${targetEnvironment}`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchPolicySystem(context, {policySystemUrl, preview}) {
        const response = await authedAxios.get(
            `/policy-system-data/${policySystemUrl}/?include=logo,navigation-items.page.versions${preview ? '&preview=true' : ''}`
        );

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchDBConnections() {
        const response = await authedAxios.get('/data-migrate-connections/');

        return response?.data?.data || [];
    },
    async migratePolicySystem(context, {policySystemId, targetEnvironment, options}) {
        // `options` will be migration configs, not the actual configuration data of the policy system
        const response = await authedAxios.post(
            `/policy-systems/${policySystemId}/migrate/${targetEnvironment}/`,
            utils.dataFormatter.serialize({stuff: {type: 'ConfigurationClone', ...options}}),
        );

        return deepCase(response?.data?.data || {}, 'camel');
    },
    async savePolicySystem(context, {data, method = 'post', url = '/policy-systems/'}) {
        const response = await authedAxios[method.toLowerCase()](
            url,
            utils.dataFormatter.serialize({stuff: {...data, type: 'PolicySystem'}})
        );

        return utils.dataFormatter.deserialize(response.data);
    },
    async removePolicySystemLogo({dispatch}, {logoId, policySystemId}) {
        await authedAxios.delete(`/policy-systems/${policySystemId}/logo/${logoId}/`);
        dispatch('fetchPolicySystems');
    },
    async fetchPages({state, commit}, queryObject) {
        if (state.policySystemId) {
            let queryString = asString(queryObject, {
                    ...defaultParamMap,
                    ...wording.searchParams,
                }),
                url = `/policy-systems/${state.policySystemId}/pages/?include=obsolete_by,versions.updated_by`;
            const response = await authedAxios.get(
                `${url}${queryString}`
            );

            commit('SET_DATA', {field: 'pages', data: utils.dataFormatter.deserialize(response.data)});
            commit('SET_DATA', {field: 'totalPageCount', data: response.data.meta.pagination.count});

            return utils.dataFormatter.deserialize(response.data);
        }
    },
    async fetchQuestionGroups({state, commit}, queryObject, includeWebForm = false) {
        let queryString = asString(queryObject, {
                ...defaultParamMap,
                ...wording.searchParams,
            }),
            url = `/policy-systems/${state.policySystemId}/question-groups/?include=obsolete_by,${includeWebForm ? 'versions.web_form.sections.inputs.options,' : ''}versions.updated_by,versions.reverted_by`,
            response = await authedAxios.get(`${url}${queryString}`);

        commit('SET_DATA', {field: 'questionGroups', data: utils.dataFormatter.deserialize(response.data)});
        if (response.data.meta?.pagination) {
            commit('SET_DATA', {field: 'totalQuestionGroupCount', data: response.data.meta.pagination.count});
        }

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchQuestionGroup({state}, {questionGroupId, includeWebForm}) {
        let url = `/policy-systems/${state.policySystemId}/question-groups/${questionGroupId}/?include=obsolete_by,versions.updated_by,versions.reverted_by${includeWebForm ? ',versions.web_form.sections.input_groups.inputs.options' : ''}`,
            response = await authedAxios.get(url);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchDiffs({state}, {oldVersionId, newVersionId, itemType, itemId}) {
        const response = await authedAxios.get(`/policy-systems/${state.policySystemId}/${kebabCase(itemType)}s/${itemId}/versions/${oldVersionId}/diffs/${newVersionId}/`);

        return deepCase(response.data, 'camel');
    },
    async fetchCompaniesWithLogos() {
        const response = await authedAxios.get(`${process.env.VUE_APP_ADMIN_URL_PLATFORM_ADMIN}/api/v1/companies/?include=logos`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchVersion({state}, {itemType, itemId, versionId, queryString = ''}) {
        const response = await authedAxios.get(`/policy-systems/${state.policySystemId}/${kebabCase(itemType)}s/${itemId}/versions/${versionId}/${queryString}`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async saveModel(context, {data, modelType, method = 'post', modelId = null, action = ''}) {
        let model = {
            type: modelType
        };

        if (method !== 'post' && modelId) {
            model.id = modelId;
        }

        const response = await callApi(
            method,
            `/policy-systems/${context.state.policySystemId || data.policySystemId}/${kebabCase(modelType)}s/${modelId ? `${modelId}/` : ''}${action}${action.length ? '/' : ''}`,
            {...data, ...model}
        );

        await context.dispatch(`fetch${modelType}s`, {
            queryString: method === 'post' ? '&page=1' : '',
        });

        return response.data ? utils.dataFormatter.deserialize(response.data) : {};
    },
    async saveVersion(context, {data, itemType, itemId, method = 'patch', versionId = null}) {
        const response = await callApi(
            method,
            `/policy-systems/${context.state.policySystemId}/${kebabCase(itemType)}s/${itemId}/versions/${versionId}/?include=updated_by`,
            {...data, type: 'Version', id: versionId}
        );

        await context.dispatch(`fetch${itemType}s`, {});

        return response.data ? utils.dataFormatter.deserialize(response.data) : {};
    },
    async searchForUser(context, {search = ''}) {
        const response = await authedAxios.get(`${process.env.VUE_APP_ADMIN_URL_USERS}/api/v1/users/?search=${search}`);

        return {
            data: utils.dataFormatter.deserialize(response.data),
            links: response.data.links,
            meta: response.data.meta,
        };
    },
    async impersonate(context, id = null) {
        await authedAxios.post(`${process.env.VUE_APP_ADMIN_URL_USERS}/api/v1/impersonate/${id ? `${id}/` : ''}`);
    },
    async fetchPage(context, {polId, pageId, preview}) {
        const response = await authedAxios.get(`/policy-system-data/${polId}/${pageId}/?include=action_bar.action_items,web_form.sections.input_groups.children.inputs.options,web_form.sections.input_groups.inputs.options${preview ? '&preview=true' : ''}`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchPageById({state}, {pageId, queryString = '?include=versions,obsolete_by,versions.action_bar,versions.web_form.sections.input_groups.inputs.options,versions.web_form.sections.input_groups.children,versions.updated_by,versions.reverted_by'}) {
        const response = await authedAxios.get(`/policy-systems/${state.policySystemId}/pages/${pageId}/${queryString}`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchWebForm(context, {policySystemId, version}) {
        const response = await authedAxios.get(`/policy-systems/${policySystemId}/pages/${version.pageId}/versions/${version.id}/web-form/${version.webForm.id}/?include=sections.input_groups.inputs.options,sections.input_groups.children`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchMainNavigation(context, {url, data}) {
        const response = await authedAxios.get(url, {params: data}),
            navItems = utils.dataFormatter.deserialize(response.data);

        context.commit('SET_DATA', {
            field: 'pageNavigationItems',
            data: navItems.filter(i => i.page?.id),
        });

        return navItems.filter(i => !i.parent?.id);
    },
    async saveNavigationItem({state}, item) {
        item.type = 'NavigationItem';

        const response = await callApi(
            item.id ? 'PATCH' : 'POST',
            `/policy-systems/${state.policySystemId || item.policySystemId}/navigation-items/${item.id ? item.id + '/' : ''}`,
            item
        );

        return utils.dataFormatter.deserialize(response.data);
    },
    async deleteItem({state}, itemId) {
        return callApi('delete', `/policy-systems/${state.policySystemId}/navigation-items/${itemId}/`);
    },
    async saveActionBar(context, {data, method = 'post', actionBarId = ''}) {
        actionBarId = (actionBarId ? `${actionBarId}/` : '');

        const response = await authedAxios[method.toLowerCase()](
            `/policy-systems/${context.state.policySystemId || data.policySystemId}/action-bars/${actionBarId}`,
            utils.dataFormatter.serialize({stuff: {...data, type: 'ActionBar'}})
        );

        if (context.state.policySystemId) {
            await context.dispatch('fetchActionBars', context.state.policySystemId);
        }

        return utils.dataFormatter.deserialize(response.data);
    },
    async deleteActionBar(context, actionBarId = '') {
        await authedAxios.delete(`/policy-systems/${context.state.policySystemId}/action-bars/${actionBarId}/`);

        await context.dispatch('fetchActionBars', context.state.policySystemId);
    },
    async fetchActionBar({state}, actionBarId) {
        const response = await authedAxios.get(
            `/policy-systems/${state.policySystemId}/action-bars/${actionBarId}/`
        );

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchActionBars({commit, state}, queryObject) {
        let queryString = asString(queryObject, {
                ...defaultParamMap,
                ...wording.searchParams,
            }),
            url = `/policy-systems/${state.policySystemId}/action-bars/?include=updated_by`;
        const response = await authedAxios.get(
            `${url}${queryString}`
        );
        commit('SET_DATA', {field: 'actionBars', data: utils.dataFormatter.deserialize(response.data)});
        commit('SET_DATA', {field: 'totalActionBarCount', data: response.data.meta.pagination.count});

        return response;
    },
    async fetchPredefined(context) {
        const response = await authedAxios.get('/web-form/predefined/?filter[predefined_type]=web_form');

        context.commit('SET_DATA', {
            field: 'predefinedWebForms',
            data: utils.dataFormatter.deserialize(response.data),
        });

        return response;
    },
    async saveActionItem({state}, item) {
        let url = (item.data.id ? `${item.data.id}/` : '');

        const response = await callApi(
            item.data.id ? 'PATCH' : 'POST',
            `/policy-systems/${state.policySystemId || item.data.policySystemId}/action-items/${url}`,
            item.data
        );

        return utils.dataFormatter.deserialize(response.data);
    },
    async deleteActionItem({state}, itemId) {
        return callApi('delete', `/policy-systems/${state.policySystemId}/action-items/${itemId}/`);
    },
    async fetchActionItems({state}, actionBarId = null) {
        let response = {};

        if (actionBarId) {
            // Fetch the items for an action bar.
            response = await callApi(
                'GET',
                `/policy-systems/${state.policySystemId}/action-bars/${actionBarId}/action-items/`,
                {type: 'actionItem'}
            );
        } else {
            // Fetch the predefined items.
            response = await callApi(
                'GET',
                `/policy-systems/${state.policySystemId}/action-items/?filter[is_predefined]=true`,
                {type: 'actionItem'}
            );
        }

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchPolicySystemById(_, policySystemId) {
        const response = await authedAxios.get(`/policy-systems/${policySystemId}/?include=logo`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async uploadLogo(_, {logo, url}) {
        let formData = new FormData();
        formData.append('file', logo.file);

        let response = await authedAxios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': getCookieValue(),
            }
        });

        return utils.dataFormatter.deserialize(response.data);
    },
    async deletePolicySystem(_, id) {
        let response = await authedAxios.delete(`/policy-systems/${id}`);

        return response.data;
    },
    async fetchAskKodiakGroups() {
        const response = await authedAxios.get(`/ask-kodiak-groups/`);

        return utils.dataFormatter.deserialize(response.data);
    }
};


export const mutations = {
    SET_ADMIN_SIDE_MENU_COLLAPSED(state, collapsed) {
        state.admin.sideMenu.collapsed = collapsed;
    },
    CLEAR_ERROR_MESSAGES(state) {
        state.errorMessages = {};
    },
    CLEAR_HIDDEN_INPUTS(state) {
        state.hiddenInputs = {};
    },
    SET_DATA(state, {field, data}) {
        state[field] = data;
    },
    CLEAR_SEARCH_FIELDS(state) {
        state.searchFields = {};
    },
    SET_SEARCH_FIELDS(state, searchField) {
        if ((typeof searchField.value !== 'boolean' && !searchField.value) || (Array.isArray(searchField.value) && !searchField.value.length)) {
            Vue.delete(state.searchFields, searchField.key);
        } else {
            Vue.set(state.searchFields, searchField.key, searchField.value);
        }
    },
};

export const state = () => {
    return {
        admin: {
            sideMenu: {
                collapsed: true,
            },
        },
        actionBars: [],
        currentAdminPolicySystem: null,
        mergeViewQuery: {},
        pageMenus: [],
        pageNavigationItems: [],
        pages: [],
        policy: {},
        policySystemId: null,
        policySystemTitle: null,
        policySystems: [],
        predefinedWebForms: [],
        questionGroups: [],
        searchFields: {},
        totalPolicySystemCount: 0,
        totalActionBarCount: 0,
        totalPageCount: 0,
        totalQuestionGroupCount: 0,
    };
};

export const storeConfig = {
    modules: {
        alerts,
        businessRulesDebugger,
        configuration,
        fakeClientStore,
        notifications,
        policySystem,
        toolbox,
        vueActivity,
        vueVersioning,
        webFormEditor,
    },
    getters,
    state: state(),
    actions,
    mutations,
};

export default new Vuex.Store(storeConfig);
