import utils from '@imt/vue-toolbox/src/utils';
import {deepCase} from '@imt/vue-toolbox/src/utils';
import versioningMixin from '@imt/vue-versioning/src/mixins/versioning';
import snakeCase from 'lodash/snakeCase';

import {authedAxios} from '@/store';

const policyDataIncludes = {
        types: {
            accommodations: 'accommodation_history.user',
            accommodation_history: '',
            attachments: '',
            agent_remarks: '',
            company: '',
            uassists: '',
            buildings: '',
            class_codes: 'premium',
            drivers: 'entity',
            forms: 'premium',
            individuals: 'entity',
            locations: 'address',
            losses: '',
            premium: '',
            policy: '',
            policy_group_snapshot: 'agency,policy_group_snapshot.mailing_address,policy_group_snapshot.named_insureds.entities',
            tax_ids: 'named_insured.entities',
        },
        includeNames: {
            named_insureds: ['entities']
        },
        relationshipNames: {
            named_insureds: ['entities']
        }
    },
    policyDataUrlType = {
        'Accommodation': 'accommodations',
        'AccommodationHistory': 'accommodation-history',
        'Activity': 'activities',
        'Attachment': 'attachments',
        'Building': 'buildings',
        'Comment': 'comments',
        'Driver': 'drivers',
        'Form': 'forms',
        'ClassCode': 'class-codes',
        'Location': 'locations',
        'Individual': 'individuals',
        'Loss': 'losses',
        'Address': 'addresses',
        'AddressInfo': 'address-info',
        'NamedInsured': 'named-insureds',
        'Policy': 'policies',
        'Version': 'versions',
        'Entity': 'entities',
        'Uassist': 'uassists',
        'TaxId': 'tax-ids',
    };

function getPolicyVersionIncludes() {
    let includes = Object.keys(policyDataIncludes.types).map(k => policyDataIncludes.types[k] ? `${k}.${policyDataIncludes.types[k]}` : k),
        adminFields = {
            forms: [
                'attachment',
                'csr_help_wording',
                'help_wording',
                'form_type',
                'screen_name',
                'state',
                'user_availability',
                'web_form',
            ],
            class_codes: [
                'code',
                'csr_only',
                'csr_help_wording',
                'help_wording',
                'state',
                'title',
                'web_form',
            ],
        };

    for (const [resourceType, fields] of Object.entries(adminFields)) {
        includes += `&fields[${resourceType}]=${fields.join(',')}`;
    }

    return includes;
}

export const getters = {
    policyDataSet(state) {
        // Remove relation keys to avoid max call stack errors when evaluating rules
        return {
            'policyGroup': {...state.policyGroup, policies: undefined, policyGroup: undefined},
            'policy': {...state.policy, group: undefined, versions: undefined},
            'version': {...state.policyVersion, policy: undefined, policyGroupSnapshot: undefined},
        };
    },
    policyVersionIsQuoted(state) {
        return !!state.policyVersion?.premium?.id;
    },
    policyVersionIsEditable(state, _getters, rootState) {
        return state.policy.id &&
            state.policyVersion.id &&
            ['in_progress', 'pending', 'temporary'].includes(state.policyVersion.processingStatus) &&
            (!!rootState.toolbox.permissions.commercial_quote_access || !!rootState.toolbox.permissions.commercial_full_access);
    },
};

export const actions = {
    async cleanupPolicy({commit}, {dataObject, onSubmission}) {
        const response = await authedAxios.post(
            `${process.env.VUE_APP_POLICY_DATA_URL}/api/v1/versions/${dataObject.version.id}/cleanup/?include=${getPolicyVersionIncludes()}${onSubmission ? '&on_submission=true' : ''}`,
            utils.dataFormatter.serialize({stuff: {
                dataObject,
                id: dataObject.version.id,
                type: 'Version'
            }})
        );

        commit('SET_POLICY_SYSTEM_DATA', {type: 'policyVersion', data: deepCase(utils.dataFormatter.deserialize(response.data), 'camel', ['extension'])});
    },
    async saveAccommodations(context, {versionId, accommodations}) {
        await context.dispatch('savePolicyOrVersion', {
            data: deepCase({
                id: versionId,
                type: 'Version',
                accommodations: accommodations.map(accommodation => {
                    let data = {
                        versionId: versionId,
                        ruleId: accommodation.rule.ruleId,
                        status: accommodation.accommodationStatus || null,
                        ruleErrorMessage: accommodation.message,
                    };

                    if (accommodation.savedAccommodation) {
                        data.type = 'Accommodation';
                        data.id = accommodation.savedAccommodation.id;
                    }

                    return data;
                }),
            }, 'snake', ['extension']),
            type: 'version',
        });
    },
    async scrubAddress(_, data) {
        const url = `${process.env.VUE_APP_POLICY_DATA_URL}/api/v1/addresses/scrub/`,
            response = await authedAxios.post(url, utils.dataFormatter.serialize({stuff: data}));

        return response.data.data;
    },
    async deletePolicyResource(_, {id, type, versionId}) {
        await authedAxios.delete(`${process.env.VUE_APP_POLICY_DATA_URL}/api/v1/versions/${versionId}/${policyDataUrlType[type]}/${id}/`);
    },
    async fetchCompany(_, {id}) {
        const response = await authedAxios.get(`${process.env.VUE_APP_ADMIN_URL_PLATFORM_ADMIN}/api/v1/companies/${id}/`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchJob(_, {jobId}) {
        let response = await authedAxios.get(`${process.env.VUE_APP_BEHANDLE_URL}/api/v1/jobs/${jobId}/`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchPolicy({commit}, policyId) {
        const response = await authedAxios.get(`${process.env.VUE_APP_POLICY_DATA_URL}/api/v1/policies/${policyId}/?include=versions,policy_comments,policy_comments.created_by`),
            policy = utils.dataFormatter.deserialize(response.data);

        commit('SET_POLICY_SYSTEM_DATA', {type: 'policy', data: policy});

        return policy;
    },
    async fetchPolicyGroupForVersion({commit}, versionId) {
        const response = await authedAxios.get(`${process.env.VUE_APP_POLICY_DATA_URL}/api/v1/versions/${versionId}/group/`),
            policyGroup = deepCase(utils.dataFormatter.deserialize(response.data), 'camel', ['extension']);

        commit('SET_POLICY_SYSTEM_DATA', {type: 'policyGroup', data: policyGroup});

        return policyGroup;
    },
    async fetchPolicyNumber(context, prefix) {
        const response = await authedAxios.get(`${process.env.VUE_APP_NUMBERS_URL}/api/v1/counter/${prefix}/`);

        return utils.dataFormatter.deserialize(response.data).number;
    },
    async fetchPolicyVersion({commit}, {versionId}) {
        const response = await authedAxios.get(`${process.env.VUE_APP_POLICY_DATA_URL}/api/v1/versions/${versionId}/?include=${getPolicyVersionIncludes()}`),
            // Need to use `deepCase` to serialize nested relations within admin fields
            policyVersion = deepCase(utils.dataFormatter.deserialize(response.data), 'camel', ['extension']);

        commit('SET_POLICY_SYSTEM_DATA', {
            type: 'policyGroup',
            data: {
                ...policyVersion.policyGroupSnapshot,
                id: policyVersion.policyGroupSnapshot.policyGroupId,
                type: 'PolicyGroup',
            }
        });

        commit('SET_POLICY_SYSTEM_DATA', {type: 'policy', data: policyVersion.policy});
        commit('SET_POLICY_SYSTEM_DATA', {type: 'policyVersion', data: policyVersion});

        return policyVersion;
    },
    async fetchProcess(_, {code, policySystemId}) {
        let response = await authedAxios.get(`${process.env.VUE_APP_BEHANDLE_URL}/api/v1/processes/?filter[code]=${code}&filter[policy_system_id]=${policySystemId || null}`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchRules({commit, state}, {effectiveDate, pageId, transactionType}) {
        const response = await authedAxios.get(`${process.env.VUE_APP_ADMIN_URL_BUSINESS_RULES}/api/v1/policy-systems/${state.policyVersion.policySystemId}/rules/`, {
                params: {
                    'filter[enabled]': true,
                    'filter[rule_type]': 'page_display,page_validation',
                    'filter[transaction_type]': transactionType,
                    'filter[obsolete_at__date__gt]': effectiveDate,
                    'filter[page_id]': pageId,
                    'filter[policy_effective_date__lte]': effectiveDate,
                    include: 'versions.actions,versions.expression_groups.children,versions.expression_groups.expressions.components.children',
                    'page[size]': 1000
                }
            }),
            rules = utils.dataFormatter.deserialize(response.data),
            pageDisplay = rules.filter(r => r.ruleType === 'page_display'),
            pageValidation = rules.filter(r => r.ruleType === 'page_validation');

        commit('SET_RULE_TYPE', {
            rules: pageDisplay.map(rule => versioningMixin.methods.sortVersions(rule).find(v =>
                !versioningMixin.methods.isPublished(v, 'revertedAt') && versioningMixin.methods.isPublished(v, 'publishedAt'))).filter(Boolean),
            type: 'pageDisplay',
        });

        commit('SET_RULE_TYPE', {
            rules: pageValidation.map(rule => versioningMixin.methods.sortVersions(rule).find(v =>
                !versioningMixin.methods.isPublished(v, 'revertedAt') && versioningMixin.methods.isPublished(v, 'publishedAt'))).filter(Boolean),
            type: 'pageValidation',
        });
    },
    async evaluateRules(_, {data}) {
        const response = await authedAxios.post(
            `${process.env.VUE_APP_ADMIN_URL_BUSINESS_RULES}/api/v1/engine/`,
            utils.dataFormatter.serialize({stuff: {type: 'RuleEngineObject', ...data}}),
        );

        // `deepCase` must be passed an object
        return deepCase({results: response?.data?.data || []}, 'camel').results;
    },
    async fetchRulesById({state}, {ids}) {
        const response = await authedAxios.get(`${process.env.VUE_APP_ADMIN_URL_BUSINESS_RULES}/api/v1/policy-systems/${state.policyVersion.policySystemId}/rules/`, {
            params: {
                'filter[id]': ids,
                include: 'versions',
            }
        });

        return utils.dataFormatter.deserialize(response.data);
    },
    async runJob(_, {processId, variables}) {
        let data = {
                process: {
                    id: processId,
                    type: 'Process',
                },
                type: 'Job',
                variables: deepCase(variables, 'snake'),
            },
            response = await authedAxios.post(`${process.env.VUE_APP_BEHANDLE_URL}/api/v1/jobs/`,
                utils.dataFormatter.serialize({
                    stuff: data,
                }),
            );

        return utils.dataFormatter.deserialize(response.data);
    },
    async savePolicyGroup({commit}, data) {
        const response = await authedAxios.patch(`${process.env.VUE_APP_POLICY_DATA_URL}/api/v1/policy-groups/${data.id}/?include=agency,mailing_address,named_insureds.entities`, utils.dataFormatter.serialize({stuff: data})),
            policyGroup = deepCase(utils.dataFormatter.deserialize(response.data), 'camel', ['extension']);

        commit('SET_POLICY_SYSTEM_DATA', {type: 'policyGroup', data: policyGroup});

        return policyGroup;
    },
    async savePolicyOrVersion({commit}, {data, type}) {
        const routeRoot = type === 'policy' ? 'policies' : 'versions',
            queryParams = type === 'policy' ? '' : `?include=${getPolicyVersionIncludes()}`,
            response = await authedAxios.patch(`${process.env.VUE_APP_POLICY_DATA_URL}/api/v1/${routeRoot}/${data.id}/${queryParams}`, utils.dataFormatter.serialize({stuff: data})),
            // Need to use `deepCase` to serialize nested relations within admin fields
            updatedObject = deepCase(utils.dataFormatter.deserialize(response.data), 'camel', ['extension']);

        commit('SET_POLICY_SYSTEM_DATA', {type: type === 'policy' ? 'policy' : 'policyVersion', data: updatedObject});

        return updatedObject;
    },
    async savePolicyResource(_, {data, method, type, versionId, query = ''}) {
        let url = `${process.env.VUE_APP_POLICY_DATA_URL}/api/v1/versions/${versionId}/${policyDataUrlType[data.type]}/`,
            includes = policyDataIncludes.types[snakeCase(type)];

        if (method !== 'post') {
            url += `${data.id}/`;
        }

        if (includes) {
            url += `?include=${includes}`;
        }

        const response = await authedAxios[method](`${url}${query}`, utils.dataFormatter.serialize({stuff: data}));

        return utils.dataFormatter.deserialize(response.data);
    },
    async submitProcess(_, {code, versionId}) {
        const response = await authedAxios.post(`${process.env.VUE_APP_POLICY_DATA_URL}/api/v1/versions/${versionId}/submit-process/`,
            utils.dataFormatter.serialize({stuff: {code, type: 'Version'}}));

        return utils.dataFormatter.deserialize(response.data.data);
    },
};

export const mutations = {
    SET_CROSS_ERRORS(state, crossErrors) {
        state.crossErrors = crossErrors;
        sessionStorage.setItem('crossErrors', JSON.stringify(crossErrors));
    },
    SET_POLICY_SYSTEM_DATA(state, {type, data}) {
        state[type] = data;
    },
    SET_RULE_TYPE(state, {rules, type}) {
        state.rules[type] = rules;
    },
    SET_PAGE(state, {pageId, pageType, policySystemId}) {
        // Caching the quote and submission page id by policy system, so they can be navigated to throughout the system when needed
        let cachedPages = JSON.parse(sessionStorage.getItem('policySystemPages')) || {};

        if (!cachedPages[policySystemId]) {
            cachedPages[policySystemId] = {};
        }

        state[`${pageType}PageId`] = pageId;
        cachedPages[policySystemId][`${pageType}PageId`] = pageId;
        sessionStorage.setItem('policySystemPages', JSON.stringify(cachedPages));
    },
};

export const state = () => {
    return {
        crossErrors: JSON.parse(sessionStorage.getItem('crossErrors')) || {underwriterAccommodation: [], agentAccommodation: [], displayOnly: []},
        debuggerIsVisible: false,
        isPreview: false,
        pageId: '',
        pageVersion: {},
        policy: {},
        policyGroup: {},
        policyVersion: {},
        policyPrefix: '',
        policySystem: {},
        policySystemLoading: false,
        quotePageId: null,
        submissionPageId: null,
        pageLoading: true,
        rules: {
            byField: {},
            pageDisplay: [],
            pageValidation: [],
        },
        selectedError: {},
    };
};

export default {
    state: state(),
    actions,
    getters,
    namespaced: true,
    mutations,
};
